import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
    routes: [{ //登录
            path: '/login',
            name: 'login',
            component: resolve => require(['@/views/login/login'], resolve),
        },
        { //找回密码
            path: '/findAccount',
            name: 'findAccount',
            component: resolve => require(['@/views/login/findAccount'], resolve),
        },
        { //数据大屏
            path: '/dataShowBig',
            name: 'dataShowBig',
            component: resolve => require(['@/views/dataShowBig/index'], resolve),
            redirect: '/dataShowBig/dataShowBigIndex', //数据大屏
            children: [{
                path: '/dataShowBig/dataShowBigIndex',
                name: 'dataShowBigIndex',
                component: resolve => require(['@/views/dataShowBig/dataShowBigIndex'], resolve),
                meta: {}
            }]
        },
        { //主体
            path: '/',
            name: 'index',
            component: resolve => require(['@/views/index/index'], resolve),
            redirect: '/dataCenter', //主体显示默认显示
            // 子
            children: [{ //系统设置
                    path: '/systemSet', //
                    name: 'systemSet',
                    component: resolve => require(['@/views/systemSet/index'], resolve),
                    redirect: '/systemSet/account', //系统设置默认显示
                    children: [{
                            path: '/systemSet/account',
                            name: 'systemSetAccount',
                            component: resolve => require(['@/views/systemSet/account'], resolve),
                            meta: {
                                name: ['系统设置', '账号设置'],
                                sign: 'systemSetAccount'
                            }
                        }, {
                            path: '/systemSet/role',
                            name: 'systemSetRole',
                            component: resolve => require(['@/views/systemSet/role'], resolve),
                            meta: {
                                name: ['系统设置', '角色设置'],
                                sign: 'systemSetRole'
                            }
                        },
                        {
                            path: '/systemSet/center',
                            name: 'systemSetCenter',
                            component: resolve => require(['@/views/systemSet/center'], resolve),
                            meta: {
                                name: ['系统设置', '中心设置'],
                                sign: 'systemSetCenter'
                            }
                        },
                        {
                            path: '/systemSet/lbjgsz',
                            name: 'lbjgsz',
                            component: resolve => require(['@/views/systemSet/lbjgsz'], resolve),
                            meta: {
                                name: ['系统设置', '量表价格设置'],
                                sign: 'lbjgsz'
                            }
                        }
                    ]
                },
                { //内容管理
                    path: '/contentManage', //
                    name: 'contentManage',
                    component: resolve => require(['@/views/contentManage/index'], resolve),
                    redirect: '/contentManage/newsList',
                    children: [{
                            path: '/contentManage/newsList',
                            name: 'contentManageNews',
                            component: resolve => require(['@/views/contentManage/newsList'], resolve),
                            meta: {
                                name: ['内容管理', '科普管理'],
                                sign: 'contentManageNews'
                            }
                        }, {
                            path: '/contentManage/pttj',
                            name: 'pttj',
                            component: resolve => require(['@/views/contentManage/pttj'], resolve),
                            meta: {
                                name: ['内容管理', '平台简介'],
                                sign: 'pttj'
                            }
                        }, {
                            path: '/contentManage/healthTrain',
                            name: 'healthTrain',
                            component: resolve => require(['@/views/contentManage/healthTrain'], resolve),
                            meta: {
                                name: ['内容管理', '康复训练'],
                                sign: 'healthTrain'
                            }
                        }, {
                            path: '/contentManage/relaxDetail',
                            name: 'relaxDetail',
                            component: resolve => require(['@/views/contentManage/relaxDetail'], resolve),
                            meta: {
                                name: ['内容管理', '放松疗法', '详情'],
                                sign: 'relaxDetail',
                                activeMenu: '/contentManage/healthTrain', // 指向他的上一级页面
                                activeInfo: true // 常亮标识
                            }
                        },
                        {
                            path: '/contentManage/musicDetail',
                            name: 'musicDetail',
                            component: resolve => require(['@/views/contentManage/musicDetail'], resolve),
                            meta: {
                                name: ['内容管理', '音乐疗法', '详情'],
                                sign: 'musicDetail',
                                activeMenu: '/contentManage/healthTrain', // 指向他的上一级页面
                                activeInfo: true // 常亮标识
                            }
                        },
                        {
                            path: '/contentManage/emotionDetail',
                            name: 'emotionDetail',
                            component: resolve => require(['@/views/contentManage/emotionDetail'], resolve),
                            meta: {
                                name: ['内容管理', '正念疗法', '详情'],
                                sign: 'emotionDetail',
                                activeMenu: '/contentManage/healthTrain', // 指向他的上一级页面
                                activeInfo: true // 常亮标识
                            }
                        },
                        {
                            path: '/contentManage/addEditNews',
                            name: 'contentManageAddEditNews',
                            component: resolve => require(['@/views/contentManage/addEditNews'], resolve),
                            meta: {
                                name: ['内容管理', '科普管理', '新增/编辑'],
                                sign: 'contentManageNews'
                            }
                        }, {
                            path: '/contentManage/recoveryList',
                            name: 'contentManageRecovery',
                            component: resolve => require(['@/views/contentManage/recoveryList'], resolve),
                            meta: {
                                name: ['内容管理', '康复管理'],
                                sign: 'contentManageRecovery'
                            }
                        },
                        {
                            path: '/contentManage/recoveryCheck',
                            name: 'contentRecoveryCheck',
                            component: resolve => require(['@/views/contentManage/recoveryCheck'], resolve),
                            meta: {
                                name: ['内容管理', '康复管理', '治疗'],
                                sign: 'contentManageRecovery'
                            }
                        },
                        {
                            path: '/contentManage/recoveryCommonAdd',
                            name: 'contentRecoveryCommonAdd',
                            component: resolve => require(['@/views/contentManage/recoveryCommonAdd'], resolve),
                            meta: {
                                name: ['内容管理', '康复管理', '治疗', '新增/编辑'],
                                sign: 'contentManageRecovery'
                            }
                        },
                        {
                            path: '/contentManage/recoveryMusicAdd',
                            name: 'contentRecoveryMusicAdd',
                            component: resolve => require(['@/views/contentManage/recoveryMusicAdd'], resolve),
                            meta: {
                                name: ['内容管理', '康复管理', '治疗', '新增/编辑'],
                                sign: 'contentManageRecovery'
                            }
                        },
                        {
                            path: '/contentManage/advertisingList',
                            name: 'contentManageAdvertising',
                            component: resolve => require(['@/views/contentManage/advertisingList'], resolve),
                            meta: {
                                name: ['内容管理', '广告管理'],
                                sign: 'contentManageAdvertising'
                            }
                        },
                        {
                            path: '/contentManage/msgList',
                            name: 'contentManageMsg',
                            component: resolve => require(['@/views/contentManage/msgList'], resolve),
                            meta: {
                                name: ['内容管理', '通知管理'],
                                sign: 'contentManageMsg'
                            }
                        },
                        {
                            path: '/contentManage/addEditMsg',
                            name: 'contentManageAddEditMsg',
                            component: resolve => require(['@/views/contentManage/addEditMsg'], resolve),
                            meta: {
                                name: ['内容管理', '通知管理', '新增/编辑'],
                                sign: 'contentManageMsg'
                            }
                        },
                        {
                            path: '/contentManage/mzxq',
                            name: 'mzxq',
                            component: resolve => require(['@/views/contentManage/mzxq'], resolve),
                            meta: {
                                name: ['业务管理', '门诊设置'],
                                sign: 'mzxq'
                            }
                        },
                        {
                            path: '/contentManage/mzrxq',
                            name: 'mzrxq',
                            component: resolve => require(['@/views/contentManage/mzrxq'], resolve),
                            meta: {
                                name: ['业务管理', '门诊排班'],
                                sign: 'mzrxq'
                            }
                        }
                    ]
                },
                { //业务管理
                    path: '/businessManage', //
                    name: 'businessManage',
                    component: resolve => require(['@/views/businessManage/index'], resolve),
                    redirect: '/businessManage/account', //系统设置默认显示
                    children: [{
                            path: '/businessManage/registrationList',
                            name: 'businessRegistration',
                            component: resolve => require(['@/views/businessManage/registrationList'], resolve),
                            meta: {
                                name: ['业务管理', '挂号管理'],
                                sign: 'businessRegistration'
                            }
                        },
                        {
                            path: '/businessManage/diagnoseList',
                            name: 'businessDiagnose',
                            component: resolve => require(['@/views/businessManage/diagnoseList'], resolve),
                            meta: {
                                name: ['业务管理', '转诊管理'],
                                sign: 'businessDiagnose'
                            }
                        },
                        {
                            path: '/businessManage/advisoryList',
                            name: 'businessAdvisory',
                            component: resolve => require(['@/views/businessManage/advisoryList'], resolve),
                            meta: {
                                name: ['业务管理', '咨询管理'],
                                sign: 'businessAdvisory'
                            }
                        },
                        {
                            path: '/businessManage/drillList',
                            name: 'businessDrill',
                            component: resolve => require(['@/views/businessManage/drillList'], resolve),
                            meta: {
                                name: ['业务管理', '训练预约'],
                                sign: 'businessDrill'
                            }
                        },
                        {
                            path: '/businessManage/testList',
                            name: 'businessTest',
                            component: resolve => require(['@/views/businessManage/testList'], resolve),
                            meta: {
                                name: ['业务管理', '测评管理'],
                                sign: 'businessTest'
                            }
                        },
                        {
                            path: '/businessManage/symptomList',
                            name: 'businessSymptomList',
                            component: resolve => require(['@/views/businessManage/symptomList'], resolve),
                            meta: {
                                name: ['业务管理', '统计症状管理'],
                                sign: 'businessSymptomList'
                            }
                        },
                        {
                            path: '/businessManage/drillTypeList',
                            name: 'businessDrillType',
                            component: resolve => require(['@/views/businessManage/drillTypeList'], resolve),
                            meta: {
                                name: ['业务管理', '训练类型管理'],
                                sign: 'businessDrillType'
                            }
                        },
                        {
                            path: '/businessManage/psg',
                            name: 'businessPsg',
                            component: resolve => require(['@/views/businessManage/psg'], resolve),
                            meta: {
                                name: ['业务管理', 'PSG检测'],
                                sign: 'psg'
                            }
                        }
                    ]
                },
                { //数据统计
                    path: '/dataCenter',
                    name: 'dataCenter',
                    component: resolve => require(['@/views/dataCenter/index'], resolve),
                    redirect: '/dataCenter/dataCenterIndex', //默认列表显示
                    children: [{
                        path: '/dataCenter/dataCenterIndex',
                        name: 'dataCenterIndex',
                        component: resolve => require(['@/views/dataCenter/dataCenterIndex'], resolve),
                        meta: {
                            name: ['数据汇总'],
                            sign: 'dataCenter'
                        }
                    }, {
                        path: '/dataCenter/business',
                        name: 'business',
                        component: resolve => require(['@/views/dataCenter/business'], resolve),
                        meta: {
                            name: ['交易记录'],
                            sign: 'dataCenter'
                        }
                    }, {
                        path: '/dataCenter/userList',
                        name: 'userList',
                        component: resolve => require(['@/views/dataCenter/userList'], resolve),
                        meta: {
                            name: ['注册用户'],
                            sign: 'dataCenter'
                        }
                    }, {
                        path: '/dataCenter/viewInfo',
                        name: 'viewInfo',
                        component: resolve => require(['@/views/dataCenter/viewInfo'], resolve),
                        meta: {
                            name: ['用户管理']
                        }
                    }, {
                        path: '/dataCenter/userInfo',
                        name: 'userInfo',
                        component: resolve => require(['@/views/dataCenter/userInfo'], resolve),
                        meta: {
                            name: ['用户信息'],
                            sign: 'dataCenter'
                        }
                    }, ]
                },
                { //业务统计
                    path: '/businessCenter',
                    name: 'businessCenter',
                    component: resolve => require(['@/views/businessCenter/index'], resolve),
                    redirect: '/businessCenter/businessCenterIndex', //默认列表显示
                    children: [{
                        path: '/businessCenter/businessCenterIndex',
                        name: 'businessCenterIndex',
                        component: resolve => require(['@/views/businessCenter/businessCenterIndex'], resolve),
                        meta: {
                            name: ['业务统计'],
                            sign: 'businessCenter'
                        }
                    }, {
                        path: '/businessCenter/businessList',
                        name: 'businessList',
                        component: resolve => require(['@/views/businessCenter/businessList'], resolve),
                        meta: {
                            name: ['交易记录'],
                            sign: 'businessCenter'
                        }
                    }, ]
                },
                { //患者管理
                    path: '/userManage',
                    name: 'userManage',
                    component: resolve => require(['@/views/userManage/index'], resolve),
                    redirect: '/userManage/userManageList', //默认列表显示
                    children: [{
                        path: '/userManage/userManageList',
                        name: 'userManageList',
                        component: resolve => require(['@/views/userManage/userManageList'], resolve),
                        meta: {
                            name: ['患者管理'],
                            sign: 'userManage'
                        }
                    }, {
                        path: '/userManage/userManageInfo',
                        name: 'userManageInfo',
                        component: resolve => require(['@/views/userManage/userManageInfo'], resolve),
                        meta: {
                            name: ['患者管理/患者信息'],
                            sign: 'userManageInfo'
                        }
                    }, {
                        path: '/userManage/viewInfo',
                        name: 'userViewInfo',
                        component: resolve => require(['@/views/userManage/viewInfo'], resolve),
                        meta: {
                            name: ['患者管理/患者信息'],
                            sign: 'userManage'
                        }
                    }, ]
                },
                { //医生管理
                    path: '/doctorManage',
                    name: 'doctorManage',
                    component: resolve => require(['@/views/doctorManage/index'], resolve),
                    redirect: '/doctorManage/doctorManageA', //默认显示医生已入驻
                    children: [{
                        path: '/doctorManage/doctorManageA',
                        name: 'doctorManageA',
                        component: resolve => require(['@/views/doctorManage/doctorManageA'], resolve),
                        meta: {
                            name: ['医生管理/已入驻'],
                            sign: 'doctorManageA'
                        }
                    }, {
                        path: '/doctorManage/doctorManageD',
                        name: 'doctorManageD',
                        component: resolve => require(['@/views/doctorManage/doctorManageD'], resolve),
                        meta: {
                            name: ['医生管理/待审核'],
                            sign: 'doctorManageD'
                        }
                    }, {
                        path: '/doctorManage/viewInfo', //已入驻
                        name: 'dovtorViewInfo',
                        component: resolve => require(['@/views/doctorManage/viewInfo'], resolve),
                        meta: {
                            name: ['医生管理/医生信息'],
                            sign: 'doctorManageA'
                        }
                    }, {
                        path: '/doctorManage/viewInfoD', //待审核
                        name: 'dovtorViewInfoD',
                        component: resolve => require(['@/views/doctorManage/viewInfoD'], resolve),
                        meta: {
                            name: ['医生管理/医生信息'],
                            sign: 'doctorManageD'
                        }
                    }, {
                        path: '/doctorManage/doctorAddEdit',
                        name: 'doctorAddEdit',
                        component: resolve => require(['@/views/doctorManage/doctorAddEdit'], resolve),
                        meta: {
                            name: ['医生管理/新增、编辑'],
                            sign: 'doctorManageA'
                        }
                    }, {
                        path: '/doctorManage/advisoryReplyList',
                        name: 'advisoryReplyList',
                        component: resolve => require(['@/views/doctorManage/advisoryReplyList'], resolve),
                        meta: {
                            name: ['医生管理/咨询模板'],
                            sign: 'doctorManageA'
                        }
                    }, ]
                },
                { //课程管理
                    path: '/courseManage',
                    name: 'courseManage',
                    component: resolve => require(['@/views/courseManage/index'], resolve),
                    redirect: '/courseManage/testList',
                    children: [{
                            path: '/courseManage/testList',
                            name: 'courseTestList',
                            component: resolve => require(['@/views/courseManage/testList'], resolve),
                            meta: {
                                name: ['课程管理', '课题测评'],
                                sign: 'courseTestList'
                            }
                        },
                        {
                            path: '/courseManage/statistics',
                            name: 'courseStatistics',
                            component: resolve => require(['@/views/courseManage/statistics'], resolve),
                            meta: {
                                name: ['课程管理', '测评统计'],
                                sign: 'courseStatistics'
                            }
                        },
                    ]
                },
                { //量表管理
                    path: '/scaleMange',
                    name: 'scaleMange',
                    component: resolve => require(['@/views/scaleMange/index'], resolve),
                    redirect: '/scaleMange/scaleList', //默认列表显示
                    children: [{
                        path: '/scaleMange/scaleList',
                        name: 'scaleMangeList',
                        component: resolve => require(['@/views/scaleMange/scaleList'], resolve),
                        meta: {
                            name: ['量表管理'],
                            sign: 'scaleMange'
                        }
                    }, {
                        path: '/scaleMange/businessList',
                        name: 'scaleMangeInfo',
                        component: resolve => require(['@/views/scaleMange/scaleInfo'], resolve),
                        meta: {
                            name: ['量表管理'],
                            sign: 'scaleMange'
                        }
                    }, ]
                },
            ]
        }
    ]
})