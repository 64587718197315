import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入公共css
import './assets/css/style.scss'
Vue.config.productionTip = false

// 引入element
import ElementUI from 'element-ui';
import './assets/css/element-variables.scss'
Vue.use(ElementUI);

// 引入axios
import http from 'http'
import axios from 'axios'
import qs from 'qs'
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.qs = qs
Vue.prototype.http = http;
Vue.prototype.HOST = "/api";
import $ from 'jquery'
// 引入公共js
import common from "./components/common"
Vue.prototype.common = common
// 打印
import Print from '@/plugs/print'
Vue.use(Print)
import webim from './components/chat/webim';
Vue.prototype.webim = webim
// 路由报错
import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 登录
router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    sessionStorage.removeItem('username');
  }
  let user = JSON.parse(sessionStorage.getItem('username'));
  if (!user && to.path != '/login') {
    next({
      path: '/login'
    })
  } else {
    next()
  }
})
// 引入编辑器     用这里的文件
import '../public/UEditor/ueditor.config'
import '../public/UEditor/ueditor.all'
import '../public/UEditor/lang/zh-cn/zh-cn'
// import '../public/UEditor/ueditor.parse'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')